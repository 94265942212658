@import '~src/scss/helpers/bootstrap_base';

.btn-primary {
    @include button-variant($primary, $primary, #FFF, darken($primary,10%), darken($primary, 10%), #FFF);
}

.btn-outline-primary {
    @include button-outline-variant($primary, #FFF);
}

.btn-danger {
    @include button-variant($danger, $danger, #FFF, darken($danger,10%), darken($danger, 10%), #FFF);
}

.btn-outline-danger {
    @include button-outline-variant($danger, #FFF);
}

.btn-success {
    @include button-variant($success, $success, #FFF, darken($success,10%), darken($success, 10%), #FFF);
}

.btn-outline-success {
    @include button-outline-variant($success, #FFF);
}

.btn-gray {
    @include button-variant($gray, $gray, #FFF, darken($gray,10%), darken($gray, 10%), #FFF);
}

.btn-outline-gray {
    @include button-outline-variant($gray, #FFF, $gray, $gray, #fff);
}

h1 {
  font-weight: 200;
}

.toast-container {
  .ngx-toastr {
    width: 450px !important;
    max-width: calc(100vw - 25px) !important;
  }

  &.toast-top-center {
    padding-top: 5px;
  }
}

.page-link {
  line-height: 1.5;
  padding: 0.3rem 0.75rem;
  font-size: 0.95rem;
}

.modal { -webkit-overflow-scrolling: touch; }

.modal-dialog {
  &.modal-xl, &.modal-lg {
    @include media-breakpoint-between(sm, xl) {
      max-width: calc(100vw - 40px) !important;
    }
  }

  .container {
    @include media-breakpoint-down(lg) {
      max-width: unset !important;
    }
  }

  .modal-body {
    padding: 0.3rem;

    @include media-breakpoint-up(sm) {
      padding: 1rem;
    }
  }

}
.modal-open {
  .modal {
    overflow-y: auto;
  }
}

.modal-backdrop {
  background-color: rgba(#000, 0);
  opacity: 1 !important;
  transition: background-color linear 150ms;
}
.modal-backdrop.show {
  opacity: 1!important;
  background-color: rgba(#000, 0.6);
  @include media-breakpoint-up(lg) {
    backdrop-filter: blur(5px);
  }
}

.row {
  clear: both;
}

h1, h2, h3, h4, h5, h6 {
  .btn {
    margin-bottom: 0.5em;
  }
}

h1,h2{
  margin: 1.5em 0 1em;
}

i, p {
  @include media-breakpoint-down(md) {
    font-size: 90%;
  }
}
.modal-lg, .modal-xl {
  @include media-breakpoint-between(md, xl) {
    max-width: 750px;
  }
}

.nav-tabs {
  .nav-item {
    .nav-link {
      color: $text-muted;
      border-top-width: 2px;
      border-radius: $border-radius $border-radius 0 0;
      &:hover {
        background-color: rgba($content-bg, 0.6);
        //color: rgba($body-color, 0.8);
      }
      &.active {
        color: $body-color;
        background-color: $content-bg;
        border-bottom-color: $content-bg;
        border-top-color: $primary!important;
      }
    }
  }
}

.ng-dropdown-panel {
  overflow: hidden;
}

.dropdown-menu {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  .dropdown-item {
    @include media-breakpoint-down(lg) {
      padding: 0.6rem 1.5rem;
    }

    &.danger {
      color: $danger;

      &:hover {
        background-color: $danger;
        color: #FFF;
      }
    }

    &.warning {
      color: $warning;

      &:hover {
        background-color: $warning;
        color: #FFF;
      }
    }
  }
}

.usericon-group .usericon {
  border: 1px solid #FFFFFF;
}
